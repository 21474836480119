// BlogDetail.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';

function BlogDetail() {
  const location = useLocation();
  const { title, content } = location.state; // Destructure title and content from state

  return (
    <div className='flex flex-col min-h-screen'>
      <Nav /> {/* Include the Navigation Bar */}
      <div className='flex flex-col items-center justify-center flex-grow p-8 bg-gray-100'>
        <div className='max-w-5xl w-full p-10 bg-white shadow-lg rounded-lg'> {/* Increased max-w to 5xl and padding to 10 */}
          <h1 className='text-4xl font-bold mb-4 text-center'>{title}</h1>
          <p className='text-gray-700 mb-6 text-center'>{content}</p>
          <div className='flex justify-center'>
            <button 
              onClick={() => window.history.back()} 
              className='bg-blue-500 text-white py-2 px-4 rounded transition duration-300 hover:bg-blue-600'
            >
              Back to Blog
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BlogDetail;