import React from 'react';

// Sample faculty data
const facultyData = [
  {
    id: 1,
    name: "Dr. Emily Rodriguez",
    image: "/path/to/emily-rodriguez.jpg",
    description: "Lead Sociologist with 15 years of research experience in urban sociology and social dynamics."
  },
  {
    id: 2,
    name: "Prof. Michael Chen",
    image: "/path/to/michael-chen.jpg",
    description: "Expert in quantitative research methods and social policy analysis with numerous published works."
  },
  {
    id: 3,
    name: "Dr. Aisha Patel",
    image: "/path/to/aisha-patel.jpg",
    description: "Specialist in cultural sociology and intersectional studies with a focus on global social movements."
  }
];

function FacultyCard({ name, image, description }) {
  return (
    <div className='flex flex-col items-center bg-white shadow-lg rounded-lg p-6 m-4 max-w-xs w-full transform transition-transform hover:scale-105 hover:shadow-2xl'>
      <div className='w-48 h-48 mb-4 overflow-hidden rounded-full border-4 border-blue-500'>
        <img 
          src={image} 
          alt={name} 
          className='w-full h-full object-cover object-center'
        />
      </div>
      <h3 className='text-2xl font-semibold text-gray-900 mb-2'>{name}</h3>
      <p className='text-gray-600 text-center'>{description}</p>
      <button className='mt-4 px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg transition duration-300 hover:bg-blue-600'>
        View Profile
      </button>
    </div>
  );
}

function About() {
  return (
    <div className='flex flex-col w-full items-center justify-center border-b border-black-900 bg-white text-black px-4 py-16 mt-20'>
      <div className='flex flex-col justify-center items-center text-center max-w-4xl w-full mb-16'>
        <h2 className='text-5xl md:text-6xl font-bold text-gray-900 mb-8'>About Us</h2>
        <p className='text-xl md:text-2xl text-gray-700 text-center max-w-3xl'>
          Our mission is to provide exceptional learning experiences that empower individuals to grow, learn, and succeed.
        </p>
      </div>
      <div className='flex flex-col items-center justify-center max-w-6xl w-full'>
        <h2 className='text-4xl md:text-5xl font-bold text-gray-900 mb-12'>Our Faculties</h2>
        <div className='flex flex-wrap justify-center items-stretch gap-8'>
          {facultyData.map((faculty) => (
            <FacultyCard 
              key={faculty.id}
              name={faculty.name}
              image={faculty.image}
              description={faculty.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default About;