import React from 'react'
import Nav from '../Components/Nav'
import CourseMain from '../Components/CourseMain'
import Footer from '../Components/Footer'

function Courses() {
  return (
    <div>
    <Nav/>
    <CourseMain/>
    <Footer/>
      
    </div>
  )
}

export default Courses
