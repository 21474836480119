import React from 'react';
import { useNavigate, NavLink } from 'react-router-dom';

function Nav() {
  const navigate = useNavigate();

  return (
    <nav className="fixed top-0 left-0 w-full flex items-center justify-between py-6 bg-white border-b border-black z-50 shadow-md">
      <div className="flex lg:w-1/3 items-center md:mx-28">
        <img 
          onClick={() => navigate('/')} 
          src='https://via.placeholder.com/150' // Replace with your actual logo URL
          alt="Logo" 
          className="h-10 w-auto cursor-pointer" 
        />
      </div>

      <div className="hidden lg:flex mx-8 my-4 lg:w-2/3 justify-center items-center text-black text-xl font-normal gap-12">
        <NavLink 
          to="/" 
          className={({ isActive }) => (isActive ? 'text-blue-500 font-bold' : 'hover:text-blue-600')}
        >
          Home
        </NavLink>
        <NavLink 
          to="/about" 
          className={({ isActive }) => (isActive ? 'text-blue-500 font-bold' : 'hover:text-blue-600')}
        >
          About Us
        </NavLink>
        <NavLink 
          to="/courses" 
          className={({ isActive }) => (isActive ? 'text-blue-500 font-bold' : 'hover:text-blue-600')}
        >
          Courses
        </NavLink>
        <NavLink 
          to="/blog" 
          className={({ isActive }) => (isActive ? 'text-blue-500 font-bold' : 'hover:text-blue-600')}
        >
          Blog
        </NavLink>
        <p className='cursor-pointer hover:text-blue-600'>Contact</p>
      </div>
    </nav>
  );
}

export default Nav;