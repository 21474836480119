import React from 'react';
import { course_tag, COURSES } from '../Constants/Constants';

function CourseMain() {
  return (
    <div className='flex flex-col items-center justify-center w-full h-full bg-white text-black pt-44 md:pt-64'>
      <div className='text-center mb-12'>
        <h3 className='text-xl pb-2'>Our Courses</h3>
        <h2 className='text-4xl font-bold pb-2'>Learn from the best MENTORS</h2>
        <h1 className='text-md md:text-xl font-light pb-6'>{course_tag}</h1>
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-4 md:px-20'>
        {COURSES.map((course, index) => (
          <div key={index} className='bg-white rounded-lg shadow-lg transition-transform transform hover:scale-105 overflow-hidden max-w-xs mx-auto'> {/* Added max-w-xs and mx-auto */}
            <img src={course.image} alt={course.name} className='w-full h-40 object-cover' /> {/* Reduced height */}
            <div className='p-4'> {/* Reduced padding */}
              <h2 className='text-lg font-bold mb-2'>{course.name}</h2> {/* Reduced text size */}
              <p className='text-sm font-light mb-4'>{course.description}</p>
              <button className='w-full border-2 rounded-lg px-4 py-2 bg-[#468DE0] text-white text-lg transition duration-300 hover:bg-[#357ABD]'>
                Join Course
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CourseMain;