
import trophy from '../Pics/trophy Background Removed.png'


export const tagline = `The best Sociology UPSE Acadmey in Kerala`;

export const smtag = `"Deep Roots in Sociology, High Scores in UPSC – Trivandrum's Trusted Academy"`;




export const hmcourse1 =`Learn from the Best Mentors`;

export const hmcourse2 = `Our efficient team of mentors are committed to providing the best and exceptional courses to get you ready to  step into the dream of a civil service job.`;

export const course_tag = (<>
    Our mentorship programs are designed specifically for civil service aspirants At Civils360,<br/> we offer a range of courses from foundation building to advanced civil service programs for each student.
    </>
) 

export const COURSES = [
    {
        name : `Prekick`,
        description : `Full time Regular classes for aspirants, that cover entire syllabus of Prelims and Mains.`,
    },
    {
        name : `Prekick`,
        description : `Full time Regular classes for aspirants, that cover entire syllabus of Prelims and Mains.`,
    }, {
        name : `Prekick`,
        description : `Full time Regular classes for aspirants, that cover entire syllabus of Prelims and Mains.`,
    }, {
        name : `Prekick`,
        description : `Full time Regular classes for aspirants, that cover entire syllabus of Prelims and Mains.`,
    },
    
]