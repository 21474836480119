import React from 'react';
import { FaInstagram, FaFacebook, FaYoutube, FaTwitter } from "react-icons/fa"; 

function Footer() {
  return (
    <div className='flex flex-col items-center justify-center w-full bg-white text-black border-t border-black-900 py-10'>
      <div className='mb-6'>
        <img 
          src='path_to_your_logo.png' // Replace with your logo path
          alt="Logo" 
          className="h-12 w-auto cursor-pointer"
        />
      </div>
      <div className='flex space-x-8 mb-6'>
        <a href="/" className='hover:text-blue-600 transition-colors'>Home</a>
        <a href="/about" className='hover:text-blue-600 transition-colors'>About Us</a>
        <a href="/courses" className='hover:text-blue-600 transition-colors'>Courses</a>
        <a href="/blog" className='hover:text-blue-600 transition-colors'>Blog</a>
        <a href="/contact" className='hover:text-blue-600 transition-colors'>Contact</a>
      </div>

      {/* Social Media Links */}
      <div className='flex space-x-8'>
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <FaFacebook className='text-2xl cursor-pointer hover:text-blue-600 transition-colors' />
        </a>
        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
          <FaInstagram className='text-2xl cursor-pointer hover:text-pink-600 transition-colors' />
        </a>
        <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
          <FaYoutube className='text-2xl cursor-pointer hover:text-red-600 transition-colors' />
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <FaTwitter className='text-2xl cursor-pointer hover:text-blue-400 transition-colors' />
        </a>
      </div>

      {/* Copyright Section */}
      <div className='mt-6 text-sm'>
        © {new Date().getFullYear()} Edsys Labs Pvt. Ltd. All rights reserved.
      </div>
    </div>
  );
}

export default Footer;