import React from 'react'
import Nav from '../Components/Nav'
import Blg from '../Components/Blg'
import Footer from '../Components/Footer'

function Blog() {
  return (
    <div>
        <Nav/>
        <Blg/>
        <Footer/>
    </div>
  )
}

export default Blog