import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Courses from './Pages/Courses';
import About from './Pages/About';
import Blog from './Pages/Blog'; // Assuming this is your Blg component
import BlogDetail from './Pages/BlogDetail'; // Import your new BlogDetail component
import './App.css';

function App() {
  return (
    <div className="flex flex-col mx-2">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-detail" element={<BlogDetail />} /> {/* New route for detailed blog */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;