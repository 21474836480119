import React from 'react'
import Nav from '../Components/Nav'
import Abt from '../Components/Abt'
import Footer from '../Components/Footer'

function About() {
  return (
    <div>
        <Nav/>
        <Abt/>
        <Footer/>
      
    </div>
  )
}

export default About
